var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-id",
                          name: "orderId",
                          label: _vm.$t("Id da venda"),
                          type: "text",
                        },
                        model: {
                          value: _vm.filters.saleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "saleId", $$v)
                          },
                          expression: "filters.saleId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-quotation_id",
                          name: "orderQuotationId",
                          label: _vm.$t("Id da cotação"),
                          type: "text",
                        },
                        model: {
                          value: _vm.filters.quotationId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "quotationId", $$v)
                          },
                          expression: "filters.quotationId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: { id: "order-store_id", required: false },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-search-sku", {
                        attrs: { id: "product-name", required: false },
                        model: {
                          value: _vm.filters.skuId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "skuId", $$v)
                          },
                          expression: "filters.skuId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-price_table",
                          type: "vue-select",
                          label: _vm.$t("Tabela de preço"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.getComboPriceTables,
                        },
                        model: {
                          value: _vm.filters.priceTableId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "priceTableId", $$v)
                          },
                          expression: "filters.priceTableId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "7" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-status",
                          name: "delivery",
                          multiple: "",
                          label: _vm.$t("Status Pedido"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.saleStatusOptions(),
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-delivery-status",
                          name: "delivery",
                          multiple: "",
                          label: _vm.$t("Status Entrega"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.deliveryStatusOptions(),
                        },
                        model: {
                          value: _vm.filters.deliveryStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "deliveryStatus", $$v)
                          },
                          expression: "filters.deliveryStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-status",
                          name: "delivery",
                          label: _vm.$t("Origem"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.saleOriginToOrderOptions(),
                        },
                        model: {
                          value: _vm.filters.origin,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "origin", $$v)
                          },
                          expression: "filters.origin",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          id: "order-customer_id",
                          label: _vm.$t("Cliente"),
                          "is-customer": "",
                          "only-active": false,
                        },
                        model: {
                          value: _vm.filters.customerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "customerId", $$v)
                          },
                          expression: "filters.customerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-period_type",
                          type: "vue-select",
                          label: _vm.$t("Tipo do período"),
                          options: _vm.orderDates(),
                          clearable: false,
                        },
                        model: {
                          value: _vm.filters.periodType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "periodType", $$v)
                          },
                          expression: "filters.periodType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "period",
                          type: "date-range-picker",
                          label: _vm.$t("Período"),
                        },
                        model: {
                          value: _vm.filters.rangeDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "rangeDate", $$v)
                          },
                          expression: "filters.rangeDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-echope_classification",
                          type: "vue-select",
                          label: _vm.$t("Classificação echope"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.echopeClassificationOptions(),
                        },
                        model: {
                          value: _vm.filters.echopeClassification,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "echopeClassification", $$v)
                          },
                          expression: "filters.echopeClassification",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-is_consigned",
                          type: "vue-select",
                          label: _vm.$t("Consignado"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.yesNoTypes(),
                        },
                        model: {
                          value: _vm.filters.isConsigned,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "isConsigned", $$v)
                          },
                          expression: "filters.isConsigned",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "order-delivery",
                          name: "delivery",
                          label: _vm.$t("Entrega"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: _vm.yesNoTypes(),
                        },
                        model: {
                          value: _vm.filters.isDelivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "isDelivery", $$v)
                          },
                          expression: "filters.isDelivery",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "bordered",
            attrs: {
              id: "order-table",
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("SALE_ORDER.NAME")
              ),
              fields: _vm.fields,
              items: _vm.orders,
              "tbody-tr-class": _vm.rowClass,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(delivery)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.delivery, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(consigned)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.consigned, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: {
                        status: row.item.status,
                        options: _vm.saleStatusOptions(),
                      },
                    }),
                    row.item.status === "Canceled"
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("datetime")(row.item.cancelationDate)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(deliveryStatus)",
                fn: function (row) {
                  return [
                    row.item.delivery
                      ? _c("e-status-badge", {
                          attrs: {
                            status: row.item.deliveryStatus,
                            options: _vm.deliveryStatusOptions(),
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "text-right", attrs: { colspan: "11" } },
                        [_vm._v(" Total ")]
                      ),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("currency")(_vm.netValue)) + " "
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-active": row.item.active,
                        "show-update":
                          row.item.status !== _vm.saleStatusEnum.COMPLETED &&
                          row.item.status !== _vm.saleStatusEnum.CANCELED,
                        "show-delete": false,
                        "show-activate": false,
                        "show-deactivate": false,
                        buttons: _vm.buttons(row.item),
                      },
                      on: {
                        "cancel-order": function ($event) {
                          return _vm.cancelOrder(row)
                        },
                        "order-read-only": function ($event) {
                          return _vm.showOrderReadOnly(row)
                        },
                        update: function ($event) {
                          return _vm.showOrderUpdate(row)
                        },
                        activate: function ($event) {
                          return _vm.activateDeactivateOrder(row)
                        },
                        deactivate: function ($event) {
                          return _vm.activateDeactivateOrder(row)
                        },
                        "return-consigned": function ($event) {
                          return _vm.showReturnConsignedModal(row)
                        },
                        "change-delivery-status": function ($event) {
                          return _vm.onChangeDeliveryStatus(row)
                        },
                        "print-order": function ($event) {
                          return _vm.onPrint(row)
                        },
                        "check-payment": function ($event) {
                          return _vm.onCheckPayment(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-color-subtitled", {
                    attrs: {
                      colors: [
                        {
                          backgroundColor: "table-success",
                          title: _vm.$t("Pago"),
                        },
                        {
                          backgroundColor: "table-danger",
                          title: _vm.$t("Cancelado"),
                        },
                        {
                          backgroundColor: "table-warning",
                          title: _vm.$t("Consignado pendente de pagamento"),
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "order-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("e-modal-order-cancelation", {
        ref: "modalCancelation",
        on: { "after-confirm": _vm.getData },
      }),
      _c("modal-return-items", {
        ref: "modalReturnConsignedItems",
        attrs: {
          "show-sale-info": "",
          "show-products": "",
          "is-erp": "",
          busy: _vm.returnItemsBusy,
        },
        on: { confirm: _vm.onConfimPartialReturnConsign },
      }),
      _c("fab", {
        attrs: { "main-tooltip": _vm.$t("Adicionar Pedido") },
        on: { click: _vm.onCreateOrder },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }